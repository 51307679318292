import { Component, HostListener, Input } from '@angular/core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-btn-scroll',
  templateUrl: './btn-scroll.component.html',
  styleUrls: ['./btn-scroll.component.scss']
})
export class BtnScrollComponent {
  icon = faArrowUp;
  constructor() { }

  @Input() show = false;
  @Input() onShow = 400;
  @Input() onHide = 200;

  scrollTop() {
    document.body.scrollTop = 0; // Safari
    document.documentElement.scrollTop = 0; // Other
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    if ((window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop) > this.onShow) {
      this.show = true;
    } else if (this.show &&
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop)
      < this.onHide) {
      this.show = false;
    }
  }
}
