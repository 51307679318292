<div class="row">
    <div class="col">
        <div class="page-header">
            <h1>Administración <small>Inscripciones</small></h1>
        </div>
    </div>
    <div class="col d-flex justify-content-end">
        <button type="button" class="btn btn-primary mt-sm-2 mb-2 mr-sm-2" (click)="open(contentModal,{})">
            <fa-icon [icon]="icon.userPlus"></fa-icon> Agregar Inscripción
        </button>
        <button type="button" class="btn btn-primary mt-sm-2 mb-2" (click)="showSearch=!showSearch">
            <fa-icon [icon]="icon.search"></fa-icon> Busqueda Avanzada
        </button>
    </div>
</div>
<div class="row mb-3" [ngbCollapse]="!showSearch">
    <div class="col">
        <app-inscripciones-search-procesador (search)="search($event)" (clear)="clearSearch()"
            [select]="select">
        </app-inscripciones-search-procesador>
    </div>
</div>
<div class="row mb-3" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" *ngFor="let item of sort.menu"
                            [ngClass]="{ 'text-primary': sort.name == item.sort }" class="text-nowrap pointer"
                            (click)="setOrder(item.sort)">
                            <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                        </th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of itemsShow ;let i=index;trackBy:trackBy">
                        <td>{{i+1}}</td>
                        <td >{{item.emsefor}}</td>
                        <td class="text-nowrap">{{item.rut}}</td>
                        <td>{{item.nombre}}</td>
                        <td>{{item.paterno}}</td>
                        <td>{{item.materno}}</td>
                        <td>{{item.nacimiento}}</td>
                        <td>{{item.unidad}}</td>
                        <td>{{item.cargo}}</td>
                        <td>{{item.actividad}}</td>
                        <td>{{item.creado}}</td>
                        <td>
                            <button class="btn btn-sm btn-primary" (click)="open(contentModal,item)">
                                <fa-icon [icon]="icon.userEdit"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-btn-scroll></app-btn-scroll>
<ng-template #contentModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{currentItem.id?'Actualizar':'Inscribir'}} Trabajador</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <app-inscripciones-form-electrico [model]="currentItem" [select]="select"></app-inscripciones-form-electrico>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary"
            (click)="save()">{{currentItem.id?'Actualizar':'Inscribir'}}</button>
    </div>
</ng-template>