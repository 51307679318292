import { Component, EventEmitter, Input, Output } from '@angular/core';
import * as _ from 'lodash';
import { rutFormat, rutValidate } from 'rut-helpers';
import { ReporteSearchForm } from '../../../_class/reporte-search-form';

@Component({
  selector: 'app-reportes-search',
  templateUrl: './reportes-search.component.html',
  styleUrls: ['./reportes-search.component.scss']
})
export class ReportesSearchComponent {

  @Output() search = new EventEmitter<ReporteSearchForm>();
  @Output() clear = new EventEmitter();

  @Input() select = {
    emsefor: [],
    unidad: [],
    cargo: [],
    actividad: []
  };

  model: ReporteSearchForm = {
    rut: null,
    nombre: null,
    paterno: null,
    materno: null,
    emsefor: null,
    unidad: null,
    cargo: null,
    actividad: null
  };

  submit() {
    const model = _(this.model).omitBy(_.isNull).value();
    if (model.rut && rutValidate(this.model.rut)) {
      model.rut = rutFormat(model.rut);
    }
    this.search.emit(model);
  }
}
