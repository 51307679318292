import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//PAGE
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';
import { LoginComponent } from './page/login/login.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { HomeComponent } from './page/home/home.component';
//GARRA
import { ReportesAdminComponent } from './garra/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchComponent } from './garra/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateComponent } from './garra/reportes/reportes-update/reportes-update.component';
import { InscripcionesAdminComponent } from './garra/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesSearchComponent } from './garra/inscripciones/inscripciones-search/inscripciones-search.component';
import { InscripcionesFormComponent } from './garra/inscripciones/inscripciones-form/inscripciones-form.component';
import { NavbarGarraComponent } from './garra/view/navbar/navbar.component';
import { DashboardMainComponent } from './garra/dashboard/dashboard-main/dashboard-main.component';
import { LayoutComponent } from './garra/view/layout/layout.component';
//SHOVEL
import { LayoutShovelComponent } from './shovel/view/layout/layout.component';
import { NavbarShovelComponent } from './shovel/view/navbar/navbar.component';
import { InscripcionesAdminShovelComponent } from './shovel/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesSearchShovelComponent } from './shovel/inscripciones/inscripciones-search/inscripciones-search.component';
import { InscripcionesFormShovelComponent } from './shovel/inscripciones/inscripciones-form/inscripciones-form.component';
import { DashboardMainShovelComponent } from './shovel/dashboard/dashboard-main/dashboard-main.component';
import { ReportesAdminShovelComponent } from './shovel/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchShovelComponent } from './shovel/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateShovelComponent } from './shovel/reportes/reportes-update/reportes-update.component';
//ELECTRICO
import { LayoutElectricoComponent } from './electrico/view/layout/layout.component';
import { NavbarElectricoComponent } from './electrico/view/navbar/navbar.component';
import { InscripcionesAdminElectricoComponent } from './electrico/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesSearchElectricoComponent } from './electrico/inscripciones/inscripciones-search/inscripciones-search.component';
import { InscripcionesFormElectricoComponent } from './electrico/inscripciones/inscripciones-form/inscripciones-form.component';
import { ReportesAdminElectricoComponent } from './electrico/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchElectricoComponent } from './electrico/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateElectricoComponent } from './electrico/reportes/reportes-update/reportes-update.component';

//PROCESADOR
import { LayoutProcesadorComponent } from './procesador/view/layout/layout.component';
import { NavbarProcesadorComponent } from './procesador/view/navbar/navbar.component';
import { InscripcionesAdminProcesadorComponent } from './procesador/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesSearchProcesadorComponent } from './procesador/inscripciones/inscripciones-search/inscripciones-search.component';
import { InscripcionesFormProcesadorComponent } from './procesador/inscripciones/inscripciones-form/inscripciones-form.component';
import { ReportesAdminProcesadorComponent } from './procesador/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchProcesadorComponent } from './procesador/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateProcesadorComponent } from './procesador/reportes/reportes-update/reportes-update.component';
//PROCESADOR2
import { LayoutProcesador2Component } from './procesador2/view/layout/layout.component';
import { NavbarProcesador2Component } from './procesador2/view/navbar/navbar.component';
import { InscripcionesAdminProcesador2Component } from './procesador2/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesSearchProcesador2Component } from './procesador2/inscripciones/inscripciones-search/inscripciones-search.component';
import { InscripcionesFormProcesador2Component } from './procesador2/inscripciones/inscripciones-form/inscripciones-form.component';
import { ReportesAdminProcesador2Component } from './procesador2/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchProcesador2Component } from './procesador2/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateProcesador2Component } from './procesador2/reportes/reportes-update/reportes-update.component';

//ALPINE
import { LayoutAlpineComponent } from './alpine/view/layout/layout.component';
import { NavbarAlpineComponent } from './alpine/view/navbar/navbar.component';
import { ReportesAdminAlpineComponent } from './alpine/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchAlpineComponent } from './alpine/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateAlpineComponent } from './alpine/reportes/reportes-update/reportes-update.component';

//SATCO
import { LayoutSatcoComponent } from './satco/view/layout/layout.component';
import { NavbarSatcoComponent } from './satco/view/navbar/navbar.component';
import { ReportesAdminSatcoComponent } from './satco/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchSatcoComponent } from './satco/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateSatcoComponent } from './satco/reportes/reportes-update/reportes-update.component';

//SKIDDER
import { LayoutSkidderComponent } from './skidder/view/layout/layout.component';
import { NavbarSkidderComponent } from './skidder/view/navbar/navbar.component';
import { ReportesAdminSkidderComponent } from './skidder/reportes/reportes-admin/reportes-admin.component';
import { ReportesSearchSkidderComponent } from './skidder/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateSkidderComponent } from './skidder/reportes/reportes-update/reportes-update.component';

//CLASIFICADO
import { LayoutClasificadoComponent } from './clasificado/view/layout/layout.component';
import { NavbarClasificadoComponent } from './clasificado/view/navbar/navbar.component';
import { ReportesSearchClasificadoComponent } from './clasificado/reportes/reportes-search/reportes-search.component';
import { ReportesUpdateClasificadoComponent } from './clasificado/reportes/reportes-update/reportes-update.component';

//OTROS
import { NgbCollapseModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { registerLocaleData } from '@angular/common';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';

import localeCL from '@angular/common/locales/es-CL';
registerLocaleData(localeCL, 'es-CL');

import { Ng9RutModule } from 'ng9-rut';
import { ReportesAdminClasificadoComponent } from './clasificado/reportes/reportes-admin/reportes-admin.component';



@NgModule({
  declarations: [
    AppComponent,
    BtnScrollComponent,
    LoginComponent,
    MainLayoutComponent,
    NavbarComponent,
    NotFoundComponent,
    ReportesAdminComponent,
    ReportesSearchComponent,
    ReportesUpdateComponent,
    InscripcionesAdminComponent,
    InscripcionesSearchComponent,
    InscripcionesFormComponent,
    DashboardMainComponent,
    HomeComponent,
    LayoutComponent,
    NavbarGarraComponent,
    LayoutShovelComponent,
    NavbarShovelComponent,
    InscripcionesAdminShovelComponent,
    InscripcionesSearchShovelComponent,
    InscripcionesFormShovelComponent,
    DashboardMainShovelComponent,
    ReportesAdminShovelComponent,
    ReportesSearchShovelComponent,
    ReportesUpdateShovelComponent,
    LayoutElectricoComponent,
    NavbarElectricoComponent,
    InscripcionesAdminElectricoComponent,
    InscripcionesSearchElectricoComponent,
    InscripcionesFormElectricoComponent,
    ReportesAdminElectricoComponent,
    ReportesSearchElectricoComponent,
    ReportesUpdateElectricoComponent,
    LayoutProcesadorComponent,
    NavbarProcesadorComponent,
    InscripcionesAdminProcesadorComponent,
    InscripcionesSearchProcesadorComponent,
    InscripcionesFormProcesadorComponent,
    ReportesAdminProcesadorComponent,
    ReportesSearchProcesadorComponent,
    ReportesUpdateProcesadorComponent,
    LayoutProcesador2Component,
    NavbarProcesador2Component,
    InscripcionesAdminProcesador2Component,
    InscripcionesSearchProcesador2Component,
    InscripcionesFormProcesador2Component,
    ReportesAdminProcesador2Component,
    ReportesSearchProcesador2Component,
    ReportesUpdateProcesador2Component,
    NavbarSkidderComponent,
    NavbarAlpineComponent,
    LayoutAlpineComponent,
    LayoutSkidderComponent,
    ReportesAdminAlpineComponent,
    ReportesAdminSkidderComponent,
    ReportesSearchAlpineComponent,
    ReportesSearchSkidderComponent,
    ReportesUpdateSkidderComponent,
    ReportesUpdateAlpineComponent,
    //SATCO
    LayoutSatcoComponent,
    NavbarSatcoComponent,
    ReportesAdminSatcoComponent,
    ReportesSearchSatcoComponent,
    ReportesUpdateSatcoComponent,
    //CLASIFICADO
    LayoutClasificadoComponent,
    NavbarClasificadoComponent,
    ReportesAdminClasificadoComponent,
    ReportesSearchClasificadoComponent,
    ReportesUpdateClasificadoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    NgbCollapseModule,
    NgbModalModule,
    Ng2GoogleChartsModule,
    FontAwesomeModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    }),
    NgSelectModule,
    Ng9RutModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'es-CL' }],
  bootstrap: [AppComponent],
  entryComponents: [ReportesUpdateComponent]
})
export class AppModule { }
