import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Ficha } from '../../../_class/ficha';
import * as _ from 'lodash';
import { AraucoGarraService } from '../../../_service/arauco-garra.service';
import { ToastrService } from 'ngx-toastr';
import { rutValidate, rutFormat } from "rut-helpers";
@Component({
  selector: 'app-reportes-update',
  templateUrl: './reportes-update.component.html',
  styleUrls: ['./reportes-update.component.scss']
})
export class ReportesUpdateComponent implements OnInit {

  ficha: Ficha;
  model: Ficha;
  select: {
    emsefor: string[],
    unidad: string[],
    actividad: string[],
    cargo: string[],
  } = {
      emsefor: [],
      unidad: [],
      actividad: [],
      cargo: []
    };
  constructor(
    public activeModal: NgbActiveModal,
    private araucoService: AraucoGarraService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.model = _.cloneDeep(this.ficha);
    if (this.model.contratista == null) {
      this.model.contratista = {
        razon_social: null
      };
      // this.model.contratista.razon_social = null
    }
  }

  async update() {
    try {
      if (this.model.trabajador && rutValidate(this.model.trabajador.rut)) {
        this.model.trabajador.rut = rutFormat(this.model.trabajador.rut)
      }
      const ficha = await this.araucoService.UpdateFicha(
        this.ficha.fic_id, this.model, { expand: 'contratista,trabajador,params,evaluacion' }).toPromise();
      this.toast.success('Ficha actualizada');
      this.activeModal.close(ficha);
    } catch (error) {
      this.toast.error('No se puede actualizar : ' + JSON.stringify(error));
    }

  }
}
