<nav class="navbar navbar-expand-lg navbar-dark bg-primary d-flex">
    <a class="navbar-brand" routerLink="/">
        <img src="/assets/logo-arauco.png" alt="logo-arauco" width="120">
    </a>
    <button class="navbar-toggler" type="button" (click)="isCollapsed=
          !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-start" id="navbarNav" (click)="isCollapsed=
    !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
        <ul class="navbar-nav">
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" (click)="dropd()" [attr.aria-expanded]="!dropdown" aria-haspopup="true">
              Simuladores
            </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown" [ngbCollapse]="dropdown">
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/garra" routerLinkActive="active">Garra</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/shovel" routerLinkActive="active">Shovel</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/electrico" routerLinkActive="active">Mantenimiento eléctrico</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/procesador" routerLinkActive="active">Procesador maderero</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/alpine" routerLinkActive="active">Torre Alpine</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/skidder" routerLinkActive="active">Skidder 6x6</a>
                    <a class="dropdown-item" routerLink="/procesador2" routerLinkActive="active">Procesador maderero V2</a>
                    <a class="dropdown-item" [class.oculto]="monos" routerLink="/satco" routerLinkActive="active">Simulador Virtual SATCO 630e</a>
                    <a class="dropdown-item" routerLink="/clasificado" routerLinkActive="active">Clasificado</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="collapse navbar-collapse justify-content-end" id="navbarNav" [ngbCollapse]="!isCollapsed">

        <ul class="navbar-nav">
            <li class="mr-2">
                <a class="text-light" style="cursor: context-menu;">Bienvenido/a {{username}}</a>
            </li>
            <li class="nav-item">
                <a class="text-light" style="cursor:pointer" (click)="logout()">Cerrar Sesión</a>
            </li>
        </ul>
    </div>
</nav>