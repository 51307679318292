import { Component, OnInit, OnDestroy } from '@angular/core';
import { AraucoGarraService, AraucoGarraState } from '../../../_service/arauco-garra.service';
import { AuthService } from '../../../_service/auth.service';
import { Subscription } from 'rxjs';
import { Pagination } from '../../../_class/pagination';
import { Inscripcion } from '../../../_class/inscripcion';
import { faSort, faSortUp, faSortDown, faSearch, faUserPlus, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { filterName } from '../../../_helpers/array-helper';
import * as _ from 'lodash';

@Component({
  selector: 'app-inscripciones-admin',
  templateUrl: './inscripciones-admin.component.html',
  styleUrls: ['./inscripciones-admin.component.scss']
})
export class InscripcionesAdminProcesadorComponent implements OnInit, OnDestroy {

  constructor(
    private araucoGarraSerice: AraucoGarraService,
    private auth: AuthService,
    private modalService: NgbModal,
    private toast: ToastrService
  ) { }
  icon = {
    userPlus: faUserPlus,
    userEdit: faUserEdit,
    search: faSearch
  };
  data: AraucoGarraState = null;

  select: {
    emsefor: string[],
    unidad: string[],
    actividad: string[],
    cargo: string[],
  } = {
      emsefor: [],
      unidad: [],
      actividad: [],
      cargo: []
    };

  page: Pagination = new Pagination({
    page: 1,
    perPage: 50,
    total: 1
  });
  items: Inscripcion[] = [];
  itemsShow: Inscripcion[] = [];
  subLogin: Subscription;

  showSearch = false;

  //#region Sorteable Table
  sort = {
    name: null,
    order: true,
    menu: [
      { sort: 'emsefor', title: 'Emsefor' },
      { sort: 'rut', title: 'Rut' },
      { sort: 'nombre', title: 'Nombre' },
      { sort: 'paterno', title: 'Paterno' },
      { sort: 'materno', title: 'Materno' },
      { sort: 'nacimiento', title: 'Nacimiento' },
      { sort: 'unidad', title: 'Unidad' },
      { sort: 'cargo', title: 'Cargo' },
      { sort: 'actividad', title: 'Actividad' },
      { sort: 'creado', title: 'Fecha de Inscripción' }
    ]
  };
  //#endregion Search

  //#region Modal
  currentItem: Inscripcion = {};
  subModal: NgbModalRef;

  async ngOnInit() {
    if (this.auth.isValid) {
      this.getData();
    }
    this.subLogin = this.auth.onLoginSuccess.subscribe(() => {
      this.getData();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async getData() {
    this.data = await this.araucoGarraSerice.getData();
    this.items = this.data.inscripciones;
    this.page.total = this.items.length;
    this.page.page = 1;
    this.select = {
      emsefor: filterName(this.data.inscripciones, 'emsefor'),
      unidad: filterName(this.data.inscripciones, 'unidad'),
      cargo: filterName(this.data.inscripciones, 'cargo'),
      actividad: filterName(this.data.inscripciones, 'actividad')
    };
    this.itemsShow = this.items.slice(this.page.init, this.page.end + 1);
  }

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.items = _.orderBy(this.items, this.sort.name, this.sort.order ? 'asc' : 'desc');
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? faSortDown : faSortUp;
    } else {
      return faSort;
    }
  }
  //#endregion Sorteable Table

  //#region Search
  search(model: Inscripcion) {
    console.log('inscripcion search', model);
    this.items = _(this.data.inscripciones.slice()).filter((x: Inscripcion) => !(
      model.actividad && model.actividad !== x.actividad ||
      model.cargo && model.cargo !== x.cargo ||
      model.emsefor && model.emsefor !== x.emsefor ||
      model.unidad && model.unidad !== x.unidad ||
      model.rut && x.rut && x.rut.indexOf(model.rut.trim()) === -1 ||
      model.nombre && x.nombre && x.nombre.toUpperCase().indexOf(model.nombre.toUpperCase()) === -1 ||
      model.paterno && x.paterno && x.paterno.toUpperCase().indexOf(model.paterno.toUpperCase()) === -1 ||
      model.materno && x.materno && x.materno.toUpperCase().indexOf(model.materno.toUpperCase()) === -1
    )).value();
    this.page.total = this.items.length;
    this.page.page = 1;
    this.itemsShow = this.items.slice(this.page.init, this.page.end + 1);
  }

  clearSearch() {
    this.items = this.data.inscripciones.slice();
    this.page.total = this.items.length;
    this.page.page = 1;
    this.itemsShow = this.items.slice(this.page.init, this.page.end + 1);
  }
  open(content, item) {
    this.currentItem = { ...item };
    this.subModal = this.modalService.open(content, { size: 'lg' });
    this.subModal.result.then((result) => {
      this.toast.success('Guardado exitosamente.');
      console.log(`Closed with: ${result}`);
    }, (reason) => {
      console.log(`Dismissed reason`);
    });
  }

  async save() {
    try {
      if(this.currentItem.id===undefined){
        await this.araucoGarraSerice.saveInscrito(this.currentItem).toPromise();
      }
      else{
        this.araucoGarraSerice.updateInscrito(this.currentItem.id, this.currentItem).toPromise();
      }
      this.getData();
      this.subModal.close();
    } catch (error) {
      this.toast.warning('Se produjo un error. Inténtalo de nuevo más tarde.');
    }
  }

  onScroll() {
    if (this.itemsShow.length < this.items.length) {
      this.page.page++;
      this.itemsShow.push(...this.items.slice(this.page.init, this.page.end + 1));
    }
  }

  trackBy(index: number, name: Inscripcion): number {
    return name.id;
  }
}
