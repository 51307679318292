import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../_service/auth.service';

@Component({
  selector: 'navbar-garra',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarGarraComponent implements OnInit {
  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }
}
