import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_service/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  username = null;
  monos= true;

  constructor(
    private session: AuthService
  ) { }

  ngOnInit(): void {
      const scope = this.session.getScope();
      this.username = scope.name;
      if (this.username == "monosdev"){
        this.monos = true
      }
      else {
        this.monos = false
      }
    }

}
