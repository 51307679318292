import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InscripcionesAdminElectricoComponent } from './electrico/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { ReportesAdminElectricoComponent } from './electrico/reportes/reportes-admin/reportes-admin.component';
import { LayoutElectricoComponent } from './electrico/view/layout/layout.component';
import { DashboardMainComponent } from './garra/dashboard/dashboard-main/dashboard-main.component';
import { InscripcionesAdminComponent } from './garra/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { ReportesAdminComponent } from './garra/reportes/reportes-admin/reportes-admin.component';
import { LayoutComponent } from './garra/view/layout/layout.component';
import { HomeComponent } from './page/home/home.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { InscripcionesAdminProcesadorComponent } from './procesador/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { InscripcionesAdminProcesador2Component } from './procesador2/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { ReportesAdminProcesadorComponent } from './procesador/reportes/reportes-admin/reportes-admin.component';
import { ReportesAdminProcesador2Component } from './procesador2/reportes/reportes-admin/reportes-admin.component';
import { LayoutProcesadorComponent } from './procesador/view/layout/layout.component';
import { LayoutProcesador2Component } from './procesador2/view/layout/layout.component';
import { DashboardMainShovelComponent } from './shovel/dashboard/dashboard-main/dashboard-main.component';
import { InscripcionesAdminShovelComponent } from './shovel/inscripciones/inscripciones-admin/inscripciones-admin.component';
import { ReportesAdminShovelComponent } from './shovel/reportes/reportes-admin/reportes-admin.component';
import { LayoutShovelComponent } from './shovel/view/layout/layout.component';
import { ReportesAdminAlpineComponent } from './alpine/reportes/reportes-admin/reportes-admin.component';
import { LayoutAlpineComponent } from './alpine/view/layout/layout.component';
import { ReportesAdminSkidderComponent } from './skidder/reportes/reportes-admin/reportes-admin.component';
import { LayoutSatcoComponent } from './satco/view/layout/layout.component';
import { ReportesAdminSatcoComponent } from './satco/reportes/reportes-admin/reportes-admin.component';
import { ReportesAdminClasificadoComponent } from './clasificado/reportes/reportes-admin/reportes-admin.component';
import { LayoutClasificadoComponent } from './clasificado/view/layout/layout.component';
// import { LayoutSkidderComponent } from './skidder/view/layout/layout.component';


const routes: Routes = [
  {
    path: '', component: MainLayoutComponent, children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path:'garra', component: LayoutComponent, children:[
          {
            path:'',
            component:ReportesAdminComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminComponent
          },
          {
            path:'dashboard',
            component:DashboardMainComponent
          }

        ]
      },
      {
        path:'shovel', component: LayoutShovelComponent, children:[
          {
            path:'',
            component:ReportesAdminShovelComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminShovelComponent
          },
          {
            path:'dashboard',
            component:DashboardMainShovelComponent
          }

        ]
      },
      {
        path:'electrico', component: LayoutElectricoComponent, children:[
          {
            path:'',
            component:ReportesAdminElectricoComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminElectricoComponent
          }

        ]
      },
      {
        path:'procesador', component: LayoutProcesadorComponent, children:[
          {
            path:'',
            component:ReportesAdminProcesadorComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminProcesadorComponent
          }

        ]
      },
      {
        path:'alpine', component: LayoutAlpineComponent, children:[
          {
            path:'',
            component:ReportesAdminAlpineComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminComponent
          }

        ]
      },
      {
        path:'satco', component: LayoutSatcoComponent, children:[
          {
            path:'',
            component:ReportesAdminSatcoComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminComponent
          }

        ]
      },
      {
        path:'skidder', component: LayoutAlpineComponent, children:[
          {
            path:'',
            component:ReportesAdminSkidderComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminComponent
          }

        ]
      },
      {
        path:'clasificado', component: LayoutClasificadoComponent, children:[
          {
            path:'',
            component:ReportesAdminClasificadoComponent
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminComponent
          }

        ]
      },
      // {
      //   path:'skidder', component: LayoutSkidderComponent, children:[
      //     {
      //       path:'',
      //       component:ReportesAdminSkidderComponent
      //     },
      //     // {
      //     //   path:'inscripciones',
      //     //   component:InscripcionesAdminProcesadorComponent
      //     // }

      //   ]
      // },
      {
        path:'procesador2', component: LayoutProcesador2Component, children:[
          {
            path:'',
            component:ReportesAdminProcesador2Component
          },
          {
            path:'inscripciones',
            component:InscripcionesAdminProcesadorComponent
          }

        ]
      },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
