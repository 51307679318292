import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  constructor(private http: HttpClient, private auth: AuthService) { }

  openFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      throw new Error(('Please disable your Pop-up blocker and try again.'));
    }
  }

  downloadFile(fileName: string, data: any, type: string) {
    const a = document.createElement('a');
    const filePDF = new Blob([data], { type });
    const fileURL = window.URL.createObjectURL(filePDF);
    a.href = fileURL;
    a.download = fileName;
    a.click();
  }

  async downloadFichaById(id: number) {
    await this.downloadFileByUrl(`${environment.endpoint}/report/empresa/ficha/${id}`);
  }

  async downloadFileByUrl(uri: string) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    const { headers: fileHeader, body: data } = await this.http.get(`${uri}`, { headers, responseType: 'arraybuffer', observe: 'response' })
      .toPromise();
    const { fileName, contentType } = this.getFileData(fileHeader);
    this.downloadFile(fileName, data, contentType);
  }

  getFileData(headers: HttpHeaders) {
    let fileName = 'file';
    const contentDisposition = headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    const contentType = headers.get('Content-Type');
    return { fileName, contentType };
  }


}
