<div class="row">
    <div class="col">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Busqueda Avanzada</h4>
                <form #inscripcionForm="ngForm" (ngSubmit)="submit()">
                    <div class="form-row">
                        <div class="form-group col-xl-3 col-md-6 col-xs-12">
                            <label for="rutInput">RUT</label>
                            <input type="text" class="form-control" [(ngModel)]="model.rut" name="rutInput" validateRut
                                formatRut placeholder="Ingrese rut ...">
                        </div>
                        <div class="form-group col-xl-3 col-md-6 col-xs-12">
                            <label for="nombreInput">Nombre</label>
                            <input type="text" class="form-control" [(ngModel)]="model.nombre" name="nombreInput"
                                placeholder="Ingrese Nombre ...">
                        </div>
                        <div class="form-group col-xl-3 col-md-6 col-xs-12">
                            <label for="PaternoInput">Paterno</label>
                            <input type="text" class="form-control" [(ngModel)]="model.paterno" name="PaternoInput"
                                placeholder="Ingrese Paterno ...">
                        </div>
                        <div class="form-group col-xl-3 col-md-6 col-xs-12">
                            <label for="MaternoInput">Materno</label>
                            <input type="text" class="form-control" [(ngModel)]="model.materno" name="MaternoInput"
                                placeholder="Ingrese Materno ...">
                        </div>
                        <div class="form-group col-xl-6 col-md-6 col-xs-12">
                            <label for="emseforInput">Emsefor</label>
                            <ng-select [items]="select.emsefor" [(ngModel)]="model.emsefor" [multiple]="true"
                                [closeOnSelect]="false" name="emseforInput" placeholder="Ingrese el unidad...">
                            </ng-select>
                        </div>
                        <div class="form-group col-xl-4 col-md-6 col-xs-12">
                            <label for="UnidadInput">Unidad</label>
                            <ng-select [items]="select.unidad" [(ngModel)]="model.unidad" name="unidadInput"
                                [multiple]="true" [closeOnSelect]="false" placeholder="Ingrese el unidad...">
                            </ng-select>
                        </div>
                        <div class="form-group col-xl-4 col-md-6 col-xs-12">
                            <label for="CargoInput">Cargo</label>
                            <ng-select [items]="select.cargo" [(ngModel)]="model.cargo" name="cargoInput"
                                [multiple]="true" [closeOnSelect]="false" placeholder="Ingrese el cargo...">
                            </ng-select>
                        </div>
                        <div class="form-group col-xl-4 col-md-6 col-xs-12">
                            <label for="ActividadInput">Actividad</label>
                            <ng-select [items]="select.actividad" [(ngModel)]="model.actividad" name="actividadInput"
                                [multiple]="true" [closeOnSelect]="false" placeholder="Ingrese el actividad...">
                            </ng-select>
                        </div>
                    </div>
                    <div class="float-right">
                        <button type="submit" class="btn btn-primary">Buscar</button>
                        <button type="reset" class="btn btn-secondary ml-2" (click)="clear.emit()">Limpiar</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<!-- <pre>{{model|json}}</pre> -->