<div class="container-fluid">
    <div class="row " *ngIf="summary!=null && summary.dataGeneral!=null">
        <div class="col-12 mt-3">
            <h4 class="text-muted">Datos Generales</h4>
        </div>
        
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].inscritos}}</h3>
                    <p class="card-text text-justify">Trabajadores inscritos en el sistema.</p>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].fichas}}</h3>
                    <p class="card-text text-justify">Evaluaciones realizadas.</p>
                </div>
            </div>
        </div>

        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].trabajadores}}</h3>
                    <p class="card-text text-justify">Trabajadores evaluados.</p>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].promedio |percent}}</h3>
                    <p class="card-text text-justify">Calificación promedio de los evaluados.</p>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].checklist |percent}}</h3>
                    <p class="card-text text-justify">Calificación promedio en checklist.</p>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2">
            <div class="card h-10rem sombras">
                <div class="card-body">
                    <h3 class="card-title text-center">{{summary.dataGeneral[0].cuestionario |percent}}</h3>
                    <p class="card-text text-justify">Calificación promedio en cuestionario.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" (window:resize)="onResize()">
        <div class="col-12 mt-5 mb-5" *ngIf="mensualChartData!=null && loading.historico">
            <h4 class="text-muted">Promedio histórico de calificaciones obtenidas ( /mes)</h4>
            <google-chart [data]="mensual"></google-chart>
            <div class="d-flex justify-content-center mb-3">
                <google-chart [data]="mensualTable"></google-chart>
            </div>
        </div>
        <div class="col-12 mt-5 mb-5" *ngIf="checklist.dataTable!=null && loading.checklist">
            <h4 class="text-muted">Promedio histórico de resultados obtenidos en checklist ( /pregunta)</h4>
            <google-chart [data]="checklist"></google-chart>
            <div class="d-flex justify-content-center mb-3">
                <google-chart [data]="checklistTable"></google-chart>
            </div>
        </div>
        <div class="col-12 mt-5 mb-5" *ngIf="cuestionario.dataTable!=null && loading.cuestionario">
            <h4 class="text-muted">Promedio histórico de resultados obtenidos en cuestionario ( /pregunta)</h4>
            <google-chart [data]="cuestionario"></google-chart>
            <div class="d-flex justify-content-center mb-3">
                <google-chart [data]="cuestionarioTable"></google-chart>
            </div>
        </div>
        <div class="col-12 mt-5 mb-5" *ngIf="scena.dataTable!=null && loading.scena">
            <h4 class="text-muted">Promedio histórico de resultados obtenidos en seguridad y producción ( /Escena)</h4>
            <google-chart [data]="scena"></google-chart>
            <div class="d-flex justify-content-center mb-3">
                <google-chart [data]="sceneTable"></google-chart>
            </div>
        </div>
    </div>    
</div>
