<div class="container-fluid text-center mt-5">
    <h1>Bienvenido/a a la plataforma de Arauco Forma</h1>
    <h5>Seleccione el proyecto del cual requiere informacion</h5>
</div>
<div class="card-group mt-5" [class.oculto]="monos">
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/garra" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/Garra.png" alt="Card image cap">
        </a>
    </div>
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/shovel" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/Shovel.png" alt="Card image cap">
        </a>
    </div>
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/electrico" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/electrico.png" alt="Card image cap">
        </a>
    </div>

</div>
<div class="card-group mt-2" [class.oculto]="monos">
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/procesador" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/procesador.png" alt="Card image cap">
        </a>
    </div>
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/alpine" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/Alpine.png" alt="Card image cap">
        </a>
    </div>
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/skidder" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/Skidder.png" alt="Card image cap">
        </a>
    </div>
</div>
<div class="card-group mt-2">
    <div class="card">
        <a class="nav-link" routerLink="/procesador2" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/procesador2.png" alt="Card image cap">
        </a>
    </div>
    <div class="card" [class.oculto]="monos">
        <a class="nav-link" routerLink="/satco" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/satco.png" alt="Card image cap">
        </a>
    </div>
    <div class="card">
        <a class="nav-link" routerLink="/clasificado" routerLinkActive="active">
            <img class="card-img-top" src="../../../assets/clasificado.png" alt="Card image cap">
        </a>
    </div>
</div>