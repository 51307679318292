<nav class="navbar navbar-expand-lg navbar-dark d-flex navv">
    <span class="navbar-text">
      ARAUCO - PROCESADOR MADERERO
    </span>
      <!-- <a class="navbar-brand" [routerLink]="[]"><small>Simulador Operación Carro Madereo Grapple</small></a> -->
      <button class="navbar-toggler" type="button" (click)="isCollapsed=
            !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-start" id="navbarNav" >
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" routerLink="/procesador" routerLinkActive="active">Reportes</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/procesador/inscripciones" routerLinkActive="active">Inscripciones</a>
          </li>
        </ul>
      </div>
    </nav>