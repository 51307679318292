import { Component, OnInit } from '@angular/core';
import { AraucoGarraService } from '../../../_service/arauco-garra.service';
import { AuthService } from '../../../_service/auth.service';
import { Subscription } from 'rxjs';
import { GoogleChartInterface } from 'ng2-google-charts';
import * as _ from 'lodash';
@Component({
  selector: 'app-dashboard-main',
  templateUrl: './dashboard-main.component.html',
  styleUrls: ['./dashboard-main.component.scss']
})
export class DashboardMainComponent implements OnInit {

  constructor(private araucoGarraService: AraucoGarraService, private sessionService: AuthService) { }
  subLogin: Subscription;
  summary: any;
  lastResolution = 0;
  // preguntas = {43: new Array(['Pregunta','Promedio']),44:new Array(['Pregunta','Promedio']),45:new Array(['Pregunta','Promedio']),
  //   46:new Array(['Pregunta','Promedio']),47:new Array(['Pregunta','Promedio']),48:new Array(['Pregunta','Promedio'])};
  mensualChartData = new Array(['Fecha', 'Checklist', 'Cuestionario', 'Produccion', 'Seguridad', 'Promedio']);
  mensualTableData = new Array(['Fecha', 'Checklist', 'Cuestionario', 'Produccion', 'Seguridad', 'Promedio', 'Cantidad']);
  preguntas = {checklist: new Array(['Pregunta', 'Calificación']), cuestionario: new Array(['Pregunta', 'Calificación'])};
  cuestionarioTableData = new Array(['Pregunta', 'Calificación', 'item']);
  loading = {checklist: false, cuestionario: false, historico: false, scena: false};
  dataScena = Array(['Escena', 'Nota produccion', 'Nota Seguridad']);
  refresh = 2;
  options = {
    title: 'Pregunta / Porcentaje de acierto (x, y)',
    chartArea: {left: 200, top: 100, right: 200, bottom: 200},
    height: 650,
    animation: {
      duration: 900,
      startup: true,
      easing: 'out',
    },
    vAxis: {
      color: '#555',
      minValue: 0,
      maxValue: 1,
      title: 'Calificación',
      format: 'percent'
    }
  };

  formatter = [
    {
      columns: [1],
      type: 'NumberFormat',
      options: {
        fractionDigits: 1,
        pattern: 'percent'
      }
    },
  ];

  formatterMensual = [
    {
      columns: [1, 2, 3, 4, 5],
      type: 'NumberFormat',
      options: {
        pattern: 'percent'
      }
    },
  ];

  formatterScene = [
    {
      columns: [1, 2],
      type: 'NumberFormat',
      options: {
        pattern: 'percent'
      }
    },
  ];


  public checklist: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: this.preguntas.checklist,
    options: this.options,
    formatters: this.formatter,
    refreshInterval : this.refresh
  };

  public cuestionario: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: this.preguntas.cuestionario,
    options: this.options,
    formatters: this.formatter,
    refreshInterval : this.refresh
  };

  public scena: GoogleChartInterface = {
    chartType: 'ColumnChart',
    dataTable: this.dataScena,
    refreshInterval : this.refresh,
    options: {
      title: 'Escena / Porcentaje de acierto (x, y)',
      // curveType: 'function',
      chartArea: {left: 200, top: 100, right: 200, bottom: 200},
      height: 650,
      animation: {
        duration: 900,
        startup: true,
        easing: 'out',
      },
      vAxis: {
        color: '#555',
        minValue: 0,
        maxValue: 1,
        format: 'percent'
      },
    },
    formatters: this.formatterScene
  };

  public mensual: GoogleChartInterface = {
    chartType: 'LineChart',
    dataTable: this.mensualChartData,
    options: {
      title: 'Mes - Año / Porcentaje de acierto (x, y)',
      // curveType: 'function',
      chartArea: {left: 200, top: 100, right: 200, bottom: 200},
      height: 650,
      animation: {
        duration: 900,
        startup: true,
        easing: 'out',
      },
      vAxis: {
        color: '#555',
        minValue: 0,
        maxValue: 1,
        format: 'percent'
      }
    },
    formatters: this.formatterMensual,
  };
  public mensualTable: GoogleChartInterface = {
    chartType: 'Table',
    dataTable: this.mensualTableData,
    formatters: this.formatterMensual,
  };
  public cuestionarioTable: GoogleChartInterface = {
    chartType: 'Table',
    dataTable: this.cuestionarioTableData,
    formatters: this.formatter,
  };
  public checklistTable: GoogleChartInterface = {
    chartType: 'Table',
    dataTable: this.preguntas.checklist,
    formatters: this.formatter,
  };
  public sceneTable: GoogleChartInterface = {
    chartType: 'Table',
    dataTable: this.dataScena,
    formatters: this.formatterScene,
  };

  ngOnInit(): void {
    if (this.sessionService.isValid) {
      this.getSummary();
    }

    this.subLogin = this.sessionService.onLoginSuccess.subscribe(() => {
      this.getSummary();
    });
  }

  getSummary() {
    this.araucoGarraService.getDataSummary()
      .subscribe((data) => {
        this.summary = data.body;
        this.agruparData(data.body)
      })
  }

  agruparData(data) {
    // Generar array con data de aciertos por pregunta (checklist y cuestionario)
    _.each(data.dataPreguntas, (pregunta) => {
      if (pregunta.ite_id > 42 && pregunta.ite_id < 49) {
        const descripcion = this.getDescripcionPregunta(pregunta.pre_id, data.preguntas);
        if (pregunta.ite_id === 48) {
          this.preguntas.checklist.push([descripcion, (pregunta.correctas / pregunta.totales)]);
        } else {
          this.preguntas.cuestionario.push([descripcion, (pregunta.correctas / pregunta.totales)]);
          this.cuestionarioTableData.push([descripcion, (pregunta.correctas / pregunta.totales), this.getItemName(pregunta.ite_id, data.items)]);
        }

      }
    });
    // Generar array con data mensual
    _.each(data.dataMensual, (mes) => {
      this.mensualChartData.push(
        [`${mes.month}-${mes.year}`, mes.checklist, mes.cuestionario, mes.produccion, mes.seguridad, mes.promedio]);
      this.mensualTableData.push(
        [`${mes.month}-${mes.year}`, mes.checklist, mes.cuestionario, mes.produccion, mes.seguridad, mes.promedio, mes.total]);
    });

    _.each(data.dataScenes, (scene) => {
      const sceneData = this.getSceneById(scene.id, data.summary.escenaData);
      this.dataScena.push([`${sceneData.tipo} ${sceneData.especie} ${sceneData.tronco.largo} de largo y
        ${sceneData.tronco.diámetro} de diámetro, ${sceneData.condición.atmosferica} y luminosidad ${sceneData.condición.luminosidad}`
        , scene.nota_produccion, scene.nota_seguridad]);
    });

    // verifica que la data este lista
    if (this.preguntas.checklist.length > 1) {
      this.loading.checklist = true;
    }
    if (this.preguntas.cuestionario.length > 1) {
      this.loading.cuestionario = true;
    }
    if (this.mensualChartData.length > 1) {
      this.loading.historico = true;
    }
    if (this.dataScena.length > 1) {
      this.loading.scena = true;
    }
  }

  // recibe un id y un array de Pregunta([pre_id, descripcion]) y devuelve la descripcion de la pregunta
  getDescripcionPregunta(id, preguntas) {
    return _.find(preguntas, (e) => {
      if (e.pre_id == id) {
        return e;
      }
    }).descripcion;
  }

  getItemName(id, items) {
    return _.find(items, function(i) {
      if (i.ite_id == id) {
        return i;
      }
    }).nombre;
  }

  getSceneById(id, scenes) {
    return _.find(scenes, (e) => {
      if (e.id === id) {
        return e;
      }
    });
  }

  onResize() {
    if(this.checklist!=null && this.checklist.component !=null){
      this.checklist.component.draw();
    }
    if(this.cuestionario!=null && this.cuestionario.component !=null){
      this.cuestionario.component.draw();
    }
    if(this.scena!=null && this.scena.component !=null){
      this.scena.component.draw();
    }
    if(this.mensual!=null && this.mensual.component !=null){
      this.mensual.component.draw();
    }
  }
}
