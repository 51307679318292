<div class="row">
    <div class="col">
        <div class="page-header">
            <h1>Administración <small>Reportes</small></h1>
        </div>
    </div>
    <div class="col d-flex justify-content-end">

        <button type="button" class="btn btn-primary mt-sm-2 mb-2 mr-sm-2" (click)="getFirstdata()">
            <fa-icon [icon]="icon.redo"></fa-icon> Actualizar
        </button>
        <button type="button" class="btn btn-success mt-sm-2 mb-2 mr-2" (click)="downloadXLSX()">
            <fa-icon [icon]="icon.fileExcel"></fa-icon> Excel
        </button>
        <button [class.oculto]="monos" [class.oculto]="esAdmin" type="button" class="btn btn-success mt-sm-2 mb-2 mr-2" (click)="downloadXLSXDATAClasificado()">
            <fa-icon [icon]="icon.fileExcel"></fa-icon> DATA
        </button>
        <button type="button" class="btn btn-primary mt-sm-2 mb-2" (click)="showSearch=!showSearch">
            <fa-icon [icon]="icon.search"></fa-icon> Busqueda Avanzada
        </button>
    </div>
</div>
<div class="row mb-3" [ngbCollapse]="!showSearch">
    <div class="col">
        <app-reportes-search-electrico (search)="search($event)" (clear)="clear()" [select]="select">
        </app-reportes-search-electrico>
    </div>
</div>
<div class="row mb-3" infinite-scroll [infiniteScrollDistance]="2" (scrolled)="onScroll()">
    <div class="col">
        <div class="table-responsive">
            <table class="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col" *ngFor="let item of sort.menu" class="text-nowrap" [ngClass]="{ 'text-primary': sort.name == item.sort }" [ngStyle]="{ cursor: 'pointer'}" (click)="setOrder(item.sort)">
                            <fa-icon [icon]="iconOrder(item.sort)"></fa-icon> {{item.title}}
                        </th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of items let i=index">
                        <td>{{i+1}}</td>
                        <td>{{item.fic_id}}</td>
                        <td class="text-nowrap">{{item.trabajador.rut}}</td>
                        <td>{{item.trabajador.nombre}} {{item.trabajador.paterno}} {{item.trabajador.materno}}</td>
                        <td>{{item.calificacion|percent}}</td>
                        <td class="text-nowrap">{{item.creado|date}}</td>
                        <td class="text-nowrap">
                            <div [class.view]="!monos" [class.oculto]="monos">
                                <pre>
                                    {{item.params.data | json}}
                                </pre>
                            </div>
                            <div [class.oculto]="!monos">
                                <button class="btn btn-sm btn-primary mr-2" (click)="open(i,item)">
                                    <fa-icon [icon]="icon.userEdit"></fa-icon>
                                </button>
                                <button class="btn btn-sm btn-danger" (click)="downloadPDF(item)">
                                    <fa-icon [icon]="icon.filePdf"></fa-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<app-btn-scroll></app-btn-scroll>