import { Component, Input } from '@angular/core';
import { Inscripcion } from '../../../_class/inscripcion';

@Component({
  selector: 'app-inscripciones-form-electrico',
  templateUrl: './inscripciones-form.component.html',
  styleUrls: ['./inscripciones-form.component.scss']
})
export class InscripcionesFormElectricoComponent {


  @Input() model: Inscripcion = {};
  @Input() select: {
    emsefor: string[],
    unidad: string[],
    actividad: string[],
    cargo: string[],
  } = {
      emsefor: [],
      unidad: [],
      actividad: [],
      cargo: []
    };
}