import { Component, OnInit, OnDestroy } from '@angular/core';
import { Pagination } from '../../../_class/pagination';
import { faSort, faSortUp, faSortDown, faSearch, faUserPlus, faUserEdit, faFilePdf, faFileExcel, faRedo } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';
import { AraucoGarraService } from '../../../_service/arauco-garra.service';
import { Ficha } from '../../../_class/ficha';
import { AuthService } from '../../../_service/auth.service';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportesUpdateComponent } from '../../../garra/reportes/reportes-update/reportes-update.component';
import { ToastrService } from 'ngx-toastr';
import { Inscripcion } from '../../../_class/inscripcion';
import { filterName } from '../../../_helpers/array-helper';
import { HttpParams } from '@angular/common/http';
import { ReporteSearchForm } from '../../../_class/reporte-search-form';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-reportes-admin-skidder',
  templateUrl: './reportes-admin.component.html',
  styleUrls: ['./reportes-admin.component.scss']
})
export class ReportesAdminSkidderComponent implements OnInit, OnDestroy {

  constructor(
    private araucoService: AraucoGarraService,
    private sessionService: AuthService,
    private modalService: NgbModal,
    private toastService: ToastrService
  ) { }

  page: Pagination = new Pagination({
    // total: null,
    perPage: 20,
    sort: '-fic_id',
    expand: ['trabajador', 'params', 'contratista', 'evaluacion']
  });

  items: Ficha[] = [];

  icon = {
    userPlus: faUserPlus,
    userEdit: faUserEdit,
    search: faSearch,
    filePdf: faFilePdf,
    fileExcel: faFileExcel,
    redo: faRedo
  };

  showSearch = false;

  select: {
    evaluacion?: any

  } = {
      evaluacion: null
    };
  inscripciones: Inscripcion[];

  subLogin: Subscription;

  //#region Sorteable Table
  sort = {
    name: 'fic_id',
    order: false,
    menu: [
      { sort: 'fic_id', title: 'Ficha' },
      { sort: 'traRut', title: 'Rut' },
      { sort: 'traNombre', title: 'Nombre' },
      { sort: 'calificacion', title: 'Calificación' },
      { sort: 'creado', title: 'Creado' }
    ]
  };

  classColor = {
    VERDE: 'text-success',
    AMARILLO: 'text-warning',
    ROJO: 'text-danger'
  };

  ngOnInit() {
    if (this.sessionService.isValid) {
      this.getFirstdata();
    }

    this.subLogin = this.sessionService.onLoginSuccess.subscribe(() => {
      this.getFirstdata();
    });
  }

  ngOnDestroy() {
    this.subLogin.unsubscribe();
  }

  async getFirstdata() {
    this.page.page = 1;
    // this.page.perPage = 20;
    const [responseFichas, responseData] = await Promise.all([
      this.araucoService.getSkidder(this.page.params).toPromise(),
      this.araucoService.getData()
    ]);
    this.page.Load(responseFichas.headers);
    this.items = responseFichas.body;
    this.inscripciones = responseData.inscripciones;
    this.select = {
      evaluacion: this.araucoService.getEvaluaciones()
    };

    
  }

  setOrder(prop: string) {
    if (this.sort.name != prop) {
      this.sort.name = prop;
      this.sort.order = true;
    } else {
      this.sort.order = !this.sort.order;
    }
    this.page.page = 1;
    this.page.sort = this.sort.order ? this.sort.name : '-' + this.sort.name
    this.getFirstdata()
  }

  iconOrder(prop: string) {
    if (this.sort.name == prop) {
      return this.sort.order ? faSortDown : faSortUp;
    } else {
      return faSort;
    }
  }

  async open(index: number, content: Ficha) {
    const modalRef = this.modalService.open(ReportesUpdateComponent, { size: 'xl' });
    modalRef.componentInstance.ficha = content;
    modalRef.componentInstance.select = this.select;
    try {
      this.items[index] = await modalRef.result;
    } catch (error) {
    }
  }

  async downloadPDF(ficha: Ficha) {
    this.toastService.info('Generando PDF...');
    try {
      await this.araucoService.DownloadFicha(ficha.fic_id);
      this.toastService.success('PDF generado exitosamente.');
    } catch (error) {
      this.toastService.error('Falla al generar PDF.');
      console.error("error", error)
    }
  }

  async downloadXLSX() {
    this.toastService.info('Generando Excel...');
    try {
      await this.araucoService.DownloadXlsxskidder();
      this.toastService.success('Excel generado exitosamente.');
    } catch (error) {
      this.toastService.error('Falla al generar Excel.');
      console.error("error", error)

    }
  }

  async onScroll() {
    if (this.items.length < this.page.total) {
      this.page.page++;
      const { body, headers } = await this.araucoService.getSkidder(this.page.params).toPromise();
      this.page.Load(headers);
      this.items.push(...body);
    } else {
      this.toastService.info("Se ha cargado el útlimo reporte.")
    }
  }


  search($event: ReporteSearchForm) {
    this.page.params = new HttpParams();
    if ($event.rut) {
      this.page.AddParams('filter[traRut]', $event.rut);
    }
    if ($event.nombre) {
      this.page.AddParams('filter[traNombre][like]', $event.nombre);
    }
    if ($event.paterno) {
      this.page.AddParams('filter[traPaterno][like]', $event.paterno);
    }
    if ($event.materno) {
      this.page.AddParams('filter[traMaterno][like]', $event.materno);
    }
    if (!isEmpty($event.emsefor)) {
      this.page.AddParams('filter[conNombre][in][]', $event.emsefor);
    }
    if (!isEmpty($event.unidad)) {
      this.page.AddParams('filter[unidad][in][]', $event.unidad);
    }
    if (!isEmpty($event.cargo)) {
      this.page.AddParams('filter[cargo][in][]', $event.cargo);
    }
    if (!isEmpty($event.actividad)) {
      this.page.AddParams('filter[actividad][in][]', $event.actividad);
    }
    this.getFirstdata();
  }

  clear() {
    this.page.params = new HttpParams();
    this.getFirstdata();
  }
}
