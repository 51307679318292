import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Inscripcion } from '../../../_class/inscripcion';
import * as _ from 'lodash';
import { rutValidate, rutFormat } from 'rut-helpers';

@Component({
  selector: 'app-inscripciones-search-electrico',
  templateUrl: './inscripciones-search.component.html',
  styleUrls: ['./inscripciones-search.component.scss']
})
export class InscripcionesSearchElectricoComponent{

  @Output() search = new EventEmitter<Inscripcion>();
  @Output() clear = new EventEmitter();

  @Input() select = {
    emsefor: [],
    unidad: [],
    cargo: [],
    actividad: [],
  };

  model: Inscripcion = {
    rut: null,
    nombre: null,
    paterno: null,
    materno: null,
    emsefor: null,
    unidad: null,
    cargo: null,
    actividad: null
  };

  submit() {
    const model = _(this.model).omitBy(_.isNull).value();
    if (model.rut && rutValidate(this.model.rut)) {
      model.rut = rutFormat(model.rut);
    }
    this.search.emit(model);
  }
}
