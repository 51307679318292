<nav class="navbar navbar-expand-lg navbar-dark d-flex navv">
  <span class="navbar-text">
    ARAUCO-GARRA
  </span>
    <!-- <a class="navbar-brand" [routerLink]="[]"><small>Simulador Operación Carro Madereo Grapple</small></a> -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=
          !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapse">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-start" id="navbarNav" >
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" routerLink="/garra" routerLinkActive="active">Reportes</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/garra/inscripciones" routerLinkActive="active">Inscripciones</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/garra/dashboard" routerLinkActive="active">Dashboard</a>
        </li> -->
      </ul>
    </div>
  </nav>