<form #inscripcionForm="ngForm">
    <div class="form-row">
        <div class="form-group col-6">
            <label for="rutInput">RUT</label>
            <input type="text" class="form-control" [ngModel]="model.rut"
                (blur)="model.rut = $event.target.value" name="rutInput" required formatRut
                placeholder="Ingrese rut ...">
        </div>
        <div class="form-group col-6">
            <label for="nacimientoInput">Fecha de Nacimiento</label>
            <input type="date" class="form-control" [(ngModel)]="model.nacimiento" name="nacimientoInput"
                placeholder="Ingrese nacimiento ...">
        </div>
        <div class="form-group col-4">
            <label for="nombreInput">Nombre</label>
            <input type="text" class="form-control" [(ngModel)]="model.nombre" name="nombreInput"
                placeholder="Ingrese Nombre ...">
        </div>
        <div class="form-group col-4">
            <label for="PaternoInput">Paterno</label>
            <input type="text" class="form-control" [(ngModel)]="model.paterno" name="PaternoInput"
                placeholder="Ingrese Paterno ...">
        </div>
        <div class="form-group col-4">
            <label for="MaternoInput">Materno</label>
            <input type="text" class="form-control" [(ngModel)]="model.materno" name="MaternoInput"
                placeholder="Ingrese Materno ...">
        </div>
    </div>
</form>