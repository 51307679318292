import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Inscripcion } from '../_class/inscripcion';
import { Contratista, Evaluacion } from 'projects/transversales/src/app/_class';
import { Ficha } from '../_class/ficha';
import { AraucoGarraConfig } from '../_class/arauco-garra-config';
import { ReportService } from './report.service';

export interface AraucoGarraState {
  inscripciones: Inscripcion[];
  contratistas: Contratista[];
  lastUpdate: AraucoGarraLastUpdate;
}

export interface AraucoGarraLastUpdate {
  lastChange: string;
}

@Injectable({
  providedIn: 'root'
})
export class AraucoGarraService {

  private base = `${environment.endpoint}/v1/araucogarra`;

  private keyState = `${environment.client.name}_araucogarra`;

  private state: AraucoGarraState = {
    inscripciones: [],
    contratistas: [],
    lastUpdate: null
  };

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private report: ReportService
  ) {
    this.getState();
  }

  private hasState() {
    return localStorage[this.keyState] != undefined;
  }

  private setState() {
    localStorage[this.keyState] = JSON.stringify(this.state);
  }

  private getState() {
    if (this.hasState()) {
      this.state = JSON.parse(localStorage[this.keyState]);
    }
  }

  public getLastUpdate() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<AraucoGarraLastUpdate>(`${this.base}/inscritos/lastUpdate`, { headers });
  }

  public getInscritos() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Inscripcion[]>(`${this.base}/inscritos`, { headers });
  }

  public updateInscrito(id, data: Inscripcion) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.put<Inscripcion>(`${this.base}/inscritos/${id}`, data, { headers });
  }

  public saveInscrito(data: Inscripcion) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.post<Inscripcion>(`${this.base}/inscritos`, data, { headers });
  }

  public getContratistas() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Contratista[]>(`${this.base}/contratistas`, { headers });
  }

  public async getData() {
    if (this.hasState()) {
      const lastUpdate = await this.getLastUpdate().toPromise();
      if (lastUpdate.lastChange != this.state.lastUpdate.lastChange) {
        const [contratistas, inscripciones] = await Promise.all([
          this.getContratistas().toPromise(),
          this.getInscritos().toPromise()
        ]);
        this.state = { contratistas, inscripciones, lastUpdate };
        this.setState();
      }
    } else {
      const [contratistas, inscripciones, lastUpdate] = await Promise.all([
        this.getContratistas().toPromise(),
        this.getInscritos().toPromise(),
        this.getLastUpdate().toPromise()
      ]);
      this.state = { contratistas, inscripciones, lastUpdate };
      this.setState();
    }
    return this.state;
  }

  public getFichas(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(this.base, { headers, params, observe: 'response' });
  }

  public getConfig() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<AraucoGarraConfig>(`${this.base}/config`, { headers });
  }

  public UpdateFicha(id: number, data: Ficha, params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.put<Ficha>(`${this.base}/ficha/${id}`, data, { headers, params });
  }

  public async DownloadXlsx() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsx`);
  }
  public async DownloadXlsxgarra() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxgarra`);
  }
  public async DownloadXlsxshovel() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxshovel`);
  }
  public async DownloadXlsxelectrico() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxelectrico`);
  }
  public async DownloadXlsxprocesador() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxprocesador`);
  }
  public async DownloadXlsxalpine() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxalpine`);
  }
  public async DownloadXlsxskidder() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxskidder`);
  }
  public async DownloadXlsxprocesadordos() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxprocesadordos`);
  }
  public async DownloadXlsxsatco() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxsatco`);
  }
  public async DownloadXlsxclasificado() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxclasificado`);
  }
  public async downloadXLSXDATAClasificado() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxdataclasificado`);
  }
  public async DownloadXlsxprocesadorv2() {
    await this.report.downloadFileByUrl(`${this.base}/downloadXlsxprocesadorv2`);
  }

  public getDataSummary() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get(`${this.base}/summary`, { headers, observe: 'response' });
  }

  public async DownloadFicha(id: number) {
    await this.report.downloadFichaById(id);
  }

  public getEvaluaciones(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Evaluacion[]>(`${this.base}/evaluacion`, { headers, params });
  }
  public getGarra(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/garra`, { headers, params, observe: 'response' });
  }
  public getShovel(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/shovel`, { headers, params, observe: 'response' });
  }
  public getElectrico(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/electrico`, { headers, params, observe: 'response' });
  }
  public getProcesador(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/procesador`, { headers, params, observe: 'response' });
  }
  public getClasificado(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/clasificado`, { headers, params, observe: 'response' });
  }
  public getProcesadordos(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/procesadordos`, { headers, params, observe: 'response' });
  }
  public getAlpine(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/alpine`, { headers, params, observe: 'response' });
  }
  public getSatco(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/satco`, { headers, params, observe: 'response' });
  }
  public getSkidder(params?: HttpParams | { [param: string]: string | string[]; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(`${this.base}/skidder`, { headers, params, observe: 'response' });
  }



}
