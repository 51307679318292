<div class="modal-header">
    <h4 class="modal-title">Actualizar Información del Reporte</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form #inscripcionForm="ngForm">
        <div class="form-row">
            <div class="form-group col-6">
                <label for="rutInput">RUT</label>
                <input type="text" class="form-control" [(ngModel)]="model.trabajador.rut" formatRut name="rutInput" required
                    placeholder="Ingrese rut ...">
            </div>
            <div class="form-group col-6">
                <label for="nacimientoInput">Fecha de Nacimiento</label>
                <input type="date" class="form-control" [(ngModel)]="model.trabajador.nacimiento" name="nacimientoInput"
                    placeholder="Ingrese nacimiento ...">
            </div>
            <div class="form-group col-4">
                <label for="nombreInput">Nombre</label>
                <input type="text" class="form-control" [(ngModel)]="model.trabajador.nombre" name="nombreInput"
                    placeholder="Ingrese Nombre ...">
            </div>
            <div class="form-group col-4">
                <label for="PaternoInput">Paterno</label>
                <input type="text" class="form-control" [(ngModel)]="model.trabajador.paterno" name="PaternoInput"
                    placeholder="Ingrese Paterno ...">
            </div>
            <div class="form-group col-4">
                <label for="MaternoInput">Materno</label>
                <input type="text" class="form-control" [(ngModel)]="model.trabajador.materno" name="MaternoInput"
                    placeholder="Ingrese Materno ...">
            </div>
            <div class="form-group col-6">
                <label for="emseforInput">Emsefor</label>
                <ng-select [items]="select.emsefor" [(ngModel)]="model.contratista.razon_social" [addTag]='true'
                    name="emseforInput" placeholder="Ingrese el emsefor...">
                </ng-select>
            </div>
            <div class="form-group col-6">
                <label for="UnidadInput">Unidad</label>
                <ng-select [items]="select.unidad" [(ngModel)]="model.params.data.unidad" [addTag]='true'
                    name="unidadInput" placeholder="Ingrese el unidad...">
                </ng-select>
            </div>
            <div class="form-group col-6">
                <label for="CargoInput">Cargo</label>
                <ng-select [items]="select.cargo" [(ngModel)]="model.params.data.cargo" [addTag]='true'
                    name="cargoInput" placeholder="Ingrese el cargo...">
                </ng-select>
            </div>
            <div class="form-group col-6">
                <label for="ActividadInput">Actividad</label>
                <ng-select [items]="select.actividad" [(ngModel)]="model.params.data.actividad" [addTag]='true'
                    name="actividadInput" placeholder="Ingrese el actividad...">
                </ng-select>
            </div>
        </div>
    </form>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="update()">Guardar</button>
    <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">Cerrar</button>
</div>